.conteneur {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-weight: 300;
}

.gauche {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    width: 20vw;
    padding: 30px 10px;
}

.droite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 80vw;
    background-color: #F6F5F2;
    padding: 30px 10px;
}

.titre{
    font-size: 50px;
    font-weight: 400;
}

.sous-titre{
    font-size: 30px;
    font-weight: 300;
}

input, select, textarea {
    width: 100%;
    height: 50px;
    border: 2px solid black;
    border-bottom: 1px solid #2B425D;
    background-color: #F6F5F2;
    font-size: 20px;
    font-weight: 300;
    padding: 10px;
}
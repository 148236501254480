.zone{
    display: flex;
    justify-content: space-evenly;
    font-weight: 300;
    padding: 30px 0;
}

.zone-titre{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding: 30px 10px;
    width: 40%;

}

.zone-texte{
    padding: 30px 10px;
    text-align: justify;
    width: 40%;
}

.zone-titre h1{
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 7px;
}

.zone-titre h4{
    font-size: 30px;
    font-weight: 300;
    text-align: left;
    padding-left: 10px;
    margin-bottom: 25px;
}

.texte-zone-produits{
    font-size: 60px;
    font-weight: 300;
}

.carte-zone-produits{
    height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../images/stock.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
}

.bordure-gauche{
    border-left: 3px solid #2B425D;
    padding-left: 20px;
    
}

.bordure-droite{
    border-right: 3px solid #2B425D;
    padding-right: 20px;
}






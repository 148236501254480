@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap');

body {
    font-family: 'Poppins', sans-serif;
}

.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}

.titre-section{
  font-size: 48px;
  font-weight: 600;
}

.titre-carre{
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 20px;
}

.texte-navbar{
  font-size: 22px;
  font-weight: 300;
}


.texte-carre{
  font-size: 25px;
  font-weight: 400;
}

.banniere{
  height: 400px;
  background-image: url('https://plombier-noelzil.fr/wp-content/uploads/2021/07/choisir-spn-equipement-sanitaire-noelzil-entreprise-1.jpg');
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;

}

.banniere2{
  height: 100vh;
  background-color: #334035;
  background-repeat: no-repeat;
  background-position:center;
  background-size: cover;
  color: #FEF810;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.texte-banniere{
  font-size: 9em;
  text-align: end;
  font-weight: 300;
  margin: 0;
}

.texte-banniere-bis{
  font-size: 0.8em;
  margin: 0;
}

.texte-banniere-petit{
  font-size: 5em;
  text-align: end;
  font-weight: 600;
}

.carre{
  background: rgb(255,239,3);
  background: linear-gradient(185deg, rgba(255,239,3,1) 0%, rgba(236,201,0,1) 100%, rgba(255,244,3,1) 100%);padding-top: 80px;
  padding-bottom: 15px;
  padding-left: 5px;
  /* margin-bottom: -50px; */
  border-left: 30px solid black;
}

.dore{
  background-color: #ECC900;
}

.bleu{
  color: rgb(0, 0, 116);
}

.left-border{
  border-left: 30px solid black;
}

.pointeur{
  cursor: pointer;
}

.hover:hover{
border-bottom: 5px solid black;}

.degrade{
  background: rgb(255,239,3);
  background: linear-gradient(185deg, rgba(255,239,3,1) 0%, rgba(236,201,0,1) 100%, rgba(255,244,3,1) 100%);
}

.hauteur{
  height: 300px;
}

.m-negatif{
  margin-right: -150px;
}

.justifier{
  text-align: justify;
}

.grille{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grille::after {
  content: "";
  width: 350px;
}

.width-carte-categorie{
  width: 350px;
  margin: 15px;
}

.carte-categorie{
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 500px;
  margin: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.texte-carte{
   width: 100%;
  /*height: 100%; */
  padding: 20px;
  background-color: black;
  opacity: 0.7;
  /* color: #ECC900; */
  color: white;
  font-size: 25px;
  font-weight: 300;
}

.marques{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.marques img{
  display: block;
  margin: 35px;
  height: 100px;
}

.banniere-categorie{
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 300px;
  margin: 20px;
  background-repeat: no-repeat;
  background-size:cover;
}

.texte-banniere-categorie{
   width: 100%;
  /*height: 100%; */
  padding: 20px;
  background-color: black;
  opacity: 0.7;
  /* color: #ECC900; */
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.couleur-description{
  background-color: rgb(77, 77, 77);
}

.texte-titre-description{
  font-size: 70px;
  font-weight: 400;
  letter-spacing: 7px;

}

.texte-description{
  text-align: justiy;
  font-size: 25px;
  font-weight: 300;
  color: white;
}

.description{
  display: flex;
  justify-content: space-evenly;
  padding: 50px 15px;
  background-color: #3F628D;
}

.carte-description
{
  width: 40%;
  height: 400px;
  padding: 30px;
  background-color: #2B425D;
  color: #F6F5F2;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.carte-description-alt
{
  width: 40%;
  height: 400px;
  padding: 30px;
  background-color: #F6F5F2;
  color: #2B425D;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.description h4{
  font-size: 50px;
  font-weight: 300;
}

.description p{
  font-size: 30px;
  font-weight: 200;
}

.carte-contact{
  font-weight: 300;
  width: 700px;
  height: 400px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: stretch;
  padding: 50px 25px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.mini-banniere{
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  margin-top: 0px;
}

.carte-mini-banniere{
  display: flex;
  align-items: flex-end;
  justify-content: end;
  font-size: 45px;
  font-weight: 900;
  padding: 20px;
  width: 40%;
  height: 200px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.titre-apropos{
  border-bottom: 2px solid #316DAE;
  font-size: 40px;
  font-weight: 300;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.texte-apropos{
  font-size: 25px;
  line-height: 50px;

}

.bt-inscription{
  margin-top: 35px;
  border-radius: 20px;
  padding: 5px;
  width: 100%;
  font-size: 25px;
  font-weight: 300;
  border: none;
}

.bt-navbar{
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 20px;
  font-weight: 300;
  width: 150px;
}

.bt-contact{
  border: none;
  color: white;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 24px;
  font-weight: 300;
  width: 150px;
}

.bg-image{
  background-image: url("./images/monde.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
}

.bg-image-2{
  background-image: url("./images/shipping.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
}

label{
  font-size: 23px;
  font-weight: 300;
  margin-bottom: 5px;
}







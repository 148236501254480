.bg-primaire{
    background-color: #185D7A;
}

.bg-secondaire{
    background-color: #FBFAF9;
}

.bg-accent-clair{
    background-color: #707980;
}

.bg-accent-fonce{
    background-color: #d15742;
}

.bg-fonce{
    background-color: #163D85;
}

.bg-degrade-primaire{
    background: linear-gradient(to right, #185D7A, #163D85);
}

.couleur-primaire{
    color: #185D7A;
}

.couleur-secondaire{
    color: #FBFAF9;
}

.couleur-accent-clair{
    color: #E2DFE1;
}

.couleur-accent-fonce{
    color: #d15742;
}

.couleur-fonce{
    color: #163D85;
}
/*make fade-in animation  */
.fade-in {
    animation: 5s ease-out fadeIn 1;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .move-left {
    animation: 1s ease-out moveLeft 1;
  }
  
  @keyframes moveLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .move-right {
    animation: 3s ease-out moveRight 1;
  }

@keyframes moveRight 
{
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
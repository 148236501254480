
.titre-footer{
  font-size: 30px;
  letter-spacing: 3px;
  font-weight: 300;
  color: white;
  margin: 10px;
}

.texte-footer{
  font-size: 20px;
  font-weight: 300;
  color: white;
  margin: 10px;
}

.texte-footer div{
  margin: 10px;
}

.ul-footer{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.li-footer{
  padding: 10px;
}

.lien{
  text-decoration: none;
  color: white;
}

.lien:hover{
  color: #d15742;
}

.brands{
    font-size: 30px;
}


